import { routes } from '@constants/routes';
import React from 'react';

import VerticalNavigationLayout from '../VerticalNavigationLayout';
import { INavItem } from '../VerticalNavigationLayout/navigation';
import { EGroupName } from '@types';
const adminNavItems: INavItem[] = [
  {
    key: 'home',
    label: 'Home',
    href: '/',
    iconName: 'Home',
    exact: true,
  },
  {
    key: 'tasks',
    label: 'Tasks',
    href: '/tasks',
    iconName: 'CheckSquare',
  },
  {
    key: 'companies',
    label: 'Companies',
    href: '/companies',
    iconName: 'Briefcase',
  },
  {
    key: 'templates',
    label: 'Templates',
    href: routes.ADMIN.TEMPLATES,
    iconName: 'FileText',
  },
  {
    key: 'superusers',
    label: 'Superusers',
    href: '/superusers',
    iconName: 'Star',
    groups: [EGroupName.ADMIN, EGroupName.LEADS],
  },
  {
    key: 'messages',
    label: 'Messages',
    href: '/messages',
    iconName: 'MessageSquare',
  },
  {
    key: 'flags',
    label: 'Flags',
    href: '/flags',
    iconName: 'Flag',
  },
];

export const getAdminLayout = (page) => (
  <VerticalNavigationLayout navItems={adminNavItems}>{page}</VerticalNavigationLayout>
);

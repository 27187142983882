import { ILeaveHRVM, IUserVM } from '@types';
import React from 'react';
import Select, { components } from 'react-select';

interface OnChangeResponse {
  userId: IUserVM['id'] | string;
  leaveId: ILeaveHRVM['id'];
}

interface SelectOption {
  value: string | null;
  label: string;
}

interface LeaveAssigneeDropdownProps {
  leaveId?: ILeaveHRVM['id'];
  users: IUserVM[];
  selectedUserId: any;
  onChange: (response: OnChangeResponse) => void;
  includeAllOption?: boolean;
}

function LeaveAssigneeDropdown({
  leaveId,
  selectedUserId,
  users,
  onChange,
  includeAllOption = false,
}: LeaveAssigneeDropdownProps) {
  const selectFieldOptions: SelectOption[] = React.useMemo(() => {
    const options = [
      { id: null, firstName: 'Unassigned', lastName: '' } as any,
      ...users.sort((a, b) => a.firstName?.localeCompare(b.firstName)),
    ];
    if (includeAllOption) {
      options.unshift({ id: 'ALL', firstName: 'Everyone', lastName: '' } as any);
    }
    return options.map((option) => ({
      value: option.id,
      label: `${option.firstName} ${option.lastName}`,
    }));
  }, [users, includeAllOption]);

  const handleChange = React.useCallback(
    (option: SelectOption) => {
      onChange({ userId: option.value, leaveId });
    },
    [onChange, leaveId],
  );

  const SelectContainer = (props) => {
    return (
      <components.SelectContainer
        {...props}
        style={{
          minWidth: 500,
        }}
        innerProps={{
          // eslint-disable-next-line react/prop-types
          ...props.innerProps,
          onClick: (e) => {
            // Prevents click events from propagating up the component tree.
            // -- we render this on top of a clickable element.
            e.stopPropagation();
          },
        }}
      />
    );
  };

  // NOTE: we want our menu to render directly to the document body so that it renders on top of everything when displayed.
  // NextJS (perhaps because of the the NestJS integration) complains that window doesn't exist on first compilation...
  // I hope we can get rid of this check once we replace NestJS and use NextJS stand-alone.
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <Select
      value={selectFieldOptions.find((option) => option.value === selectedUserId)}
      onChange={handleChange}
      options={selectFieldOptions}
      components={{ SelectContainer, IndicatorSeparator: null }}
      menuPortalTarget={window.document.body}
      styles={{
        container: (styles) => ({
          ...styles,
          minWidth: 200,
          height: 45,
          width: 307,
        }),
        control: (styles) => ({
          ...styles,
          width: '100%',
          height: '100%',
        }),
      }}
      blurInputOnSelect
      isSearchable
    />
  );
}

export default LeaveAssigneeDropdown;

import React, { useCallback, useMemo, useState } from 'react';
import { Router } from 'router';
import { useLocalStorage } from 'usehooks-ts';
import usePagination from 'hooks/usePagination';
import moment from 'moment';

import useApi from '@api/transportLayer';
import TableSortable from '@components/TableSortable/TableSortable';
import Badge, { BadgeColor } from '@components/v2/Badge/Badge';
import { PageTitle } from '@components/v2/PageTitle';
import { ELeaveStatus, ELeaveType, EPlanStatus } from '@types';
import shouldBeLoggedIn from '@utils/should-be-logged-in';
import LeaveAssigneeDropdown from '../Shared/LeaveAssigneeDropdown';
import styles from './HomePage.module.scss';

const HomePage = () => {
  const [assigneeIdFilter, setAssigneeIdFilter] = useLocalStorage('assigneeIdFilter', null);
  const { itemsPerPage, page, paginate, setItemsPerPage } = usePagination();
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: number }[]>([{ key: 'title', direction: 1 }]);
  const [leaveSortConfig, setLeaveSortConfig] = useState<{ key: string; direction: number }[]>([
    { direction: -1, key: 'created_at' },
  ]);
  const ordering = useMemo(() => {
    return sortConfig
      .reduce((memo, config) => {
        memo.push(config.direction == 1 ? config.key : '-' + config.key);
        return memo;
      }, [])
      .join(',');
  }, [sortConfig]);

  const leaveOrdering = useMemo(() => {
    return leaveSortConfig
      .reduce((memo, config) => {
        memo.push(config.direction == 1 ? config.key : '-' + config.key);
        return memo;
      }, [])
      .join(',');
  }, [leaveSortConfig]);

  const {
    itemsPerPage: itemsPerPagePending,
    page: pagePending,
    paginate: paginatePending,
    setItemsPerPage: setItemsPerPagePending,
  } = usePagination();

  const { data: superusers } = useApi.Admin.User.getSuperusers({}, { staleTime: 60000 });
  const { mutateAsync: updateLeaveAdmin } = useApi.Admin.Leave.update();

  const assigneeIdParams = React.useMemo(() => {
    const params = { assignee: assigneeIdFilter ?? '' };
    if (assigneeIdFilter === 'ALL') delete params.assignee;
    return params;
  }, [assigneeIdFilter]);

  const { data: leaveData, refetch: refetchLeaves } = useApi.Admin.Leave.getMany(
    {
      ...assigneeIdParams,
      unconfirmed: true,
      ordering: leaveOrdering,
      limit: itemsPerPagePending,
      offset: Math.max(0, pagePending - 1) * itemsPerPagePending,
    },
    {
      keepPreviousData: true,
    },
  );

  const { refetch: loadAllLeaves } = useApi.Admin.Leave.getMany(
    {
      ...assigneeIdParams,
      unconfirmed: true,
      ordering: leaveOrdering,
      limit: 10000,
    },
    {
      enabled: false,
    },
  );
  const onExportLeaveData = useCallback(async () => {
    const { data } = await loadAllLeaves();
    return data.results;
  }, [loadAllLeaves]);
  const leaves = leaveData?.results;
  const leaveCount = leaveData?.count || 0;

  const { data: planData, refetch: refetchPlans } = useApi.Admin.Plan.getMany(
    {
      ...assigneeIdParams,
      has_pending_tasks: true,
      archived: false,
      ordering: ordering,
      limit: itemsPerPage,
      offset: Math.max(0, page - 1) * itemsPerPage,
    },
    {
      keepPreviousData: true,
      initialData: {
        count: 0,
        results: [],
      },
    },
  );
  const { refetch: loadAllPlans } = useApi.Admin.Plan.getMany(
    {
      ...assigneeIdParams,
      has_pending_tasks: true,
      archived: false,
      ordering: ordering,
      limit: 10000,
    },
    {
      enabled: false,
    },
  );
  const onExportPlansData = useCallback(async () => {
    const { data } = await loadAllPlans();
    return data.results;
  }, [loadAllPlans]);

  const count = planData?.count || 0;
  const plans = planData?.results || [];

  const onLeaveAssigneeChange = React.useCallback(
    async ({ userId, leaveId }) => {
      const updatedLeave = await updateLeaveAdmin({
        leaveId,
        assigneeId: userId,
      });
      const leaveIndex = leaves.findIndex((leave) => leave.id === updatedLeave.id);

      if (leaveIndex !== -1) {
        refetchLeaves();
      } else {
        refetchPlans();
      }
    },
    [leaves, updateLeaveAdmin, refetchLeaves, refetchPlans],
  );

  if (!plans || !superusers || !leaves) {
    return null;
  }

  const planStatusMap = {
    NEEDS_REVIEW: 'Needs Review',
    PENDING_TASKS: 'Pending Tasks',
  };
  const leaveStatusMap = {
    [ELeaveStatus.DRAFT]: 'Draft',
    [ELeaveStatus.SENT_TO_EMPLOYEE]: 'Waiting on Employee',
    [ELeaveStatus.SENT_TO_HR]: 'Waiting on HR',
    [ELeaveStatus.APPROVED_EMPLOYEE]: 'Under Review',
    [ELeaveStatus.APPROVED_HR]: 'Active',
    [ELeaveStatus.REJECTED_HR]: 'Rejected by HR',
  };

  const getAssigneeForLeave = (leave) => {
    if (leave.assigneeId) {
      const assignee = superusers.find((u) => u.id == leave.assigneeId);
      return `${assignee?.firstName} ${assignee?.lastName}`;
    }
    return '';
  };

  return (
    <>
      <div className={styles.container}>
        <PageTitle title="Home" />
        <div className={styles.assigneeFilter}>
          <div className={styles.header}>Filter By</div>
          <LeaveAssigneeDropdown
            selectedUserId={assigneeIdFilter}
            users={superusers}
            onChange={({ userId }) => {
              setAssigneeIdFilter(userId || null);
            }}
            includeAllOption
          />
        </div>
      </div>
      <TableSortable
        headerContent={
          <div>
            <h3 className={styles.subHeader} style={{ width: '100%' }}>
              Needs Review
            </h3>
          </div>
        }
        exportData={onExportPlansData}
        data={plans}
        onRowClick={(plan, e) => {
          if (e.metaKey || e.ctrlKey) {
            return window.open(`${window.location.href}plan?planId=${plan.id}`, '_blank');
          }
          if (plan.status === EPlanStatus.NEEDS_REVIEW) {
            return Router.push(`/plan?planId=${plan.id}&tab=LEAVE`);
          }
          Router.push(`/plan?planId=${plan.id}&tab=MY_TASKS`);
        }}
        // persistSortName="planSortColumns"
        columns={[
          {
            key: 'title',
            label: 'Plan',
            sort: true,
            // eslint-disable-next-line react/display-name
            render: (plan) => {
              const subtext =
                plan.leave?.leaveType !== ELeaveType.PARENTAL
                  ? null
                  : plan.leave?.isPregnant
                  ? 'Birthing Parent'
                  : 'Non-Birthing Parent';
              return (
                <>
                  <div className={styles.leaveType}>{plan?.leave?.leaveType}</div>
                  <div>{plan.title}</div>
                  {subtext && <div className={styles.leaveTypeSubtext}>{subtext}</div>}
                </>
              );
            },
            renderCSV: (plan) => plan.title,
          },
          { key: 'company__name', label: 'Company', sort: true, render: (plan) => `${plan.company.name}` },
          {
            key: 'due_date',
            label: 'Leave Date',
            sort: true,
            // TODO convert this to local formatting
            render: (plan) => moment(plan.dueDate).format('MM/DD/YYYY'),
          },
          {
            key: 'pending_tasks_count',
            label: 'Status',
            sort: true,
            // eslint-disable-next-line react/display-name
            render: (plan) => <span className={styles[plan.status]}>{planStatusMap[plan.status]}</span>,
            renderCSV: (plan) => planStatusMap[plan.status],
          },
          {
            key: 'fmla',
            label: 'FMLA Eligibility',
            sort: true,
            // eslint-disable-next-line react/display-name
            render: (plan) => (
              <span className={styles.fmlaBadge}>{plan.fmla && <Badge color={BadgeColor.GRASS}>Eligible</Badge>}</span>
            ),
            renderCSV: (plan) => (plan.fmla ? 'Eligible' : null),
          },
          {
            key: 'leave__assignee__first_name',
            label: 'Assigned',
            sort: true,
            // eslint-disable-next-line react/display-name
            render: (plan) =>
              plan.leave && (
                <span className={styles.assigneeCell}>
                  <LeaveAssigneeDropdown
                    selectedUserId={plan.leave.assigneeId}
                    users={superusers}
                    onChange={onLeaveAssigneeChange}
                    leaveId={plan.leave.id}
                  />
                </span>
              ),
            renderCSV: (plan) => (plan.leave ? getAssigneeForLeave(plan.leave) : ''),
          },
        ]}
        initialSort={sortConfig}
        onServerSort={setSortConfig}
        serverPagination={{ count, onPaginate: paginate, itemsPerPage, setItemsPerPage }}
      />
      <TableSortable
        headerContent={
          <div className={styles.headerFlexBasic}>
            <h3 className={styles.subHeader}>Pending Leaves</h3>
          </div>
        }
        exportData={onExportLeaveData}
        key={'leaves'}
        data={leaves}
        columns={[
          {
            key: 'ee_name',
            label: 'Employee',
            sort: true,
            // eslint-disable-next-line react/display-name
            render: (leave) => {
              const subtext =
                leave.leaveType !== ELeaveType.PARENTAL
                  ? null
                  : leave.isPregnant
                  ? 'Birthing Parent'
                  : 'Non-Birthing Parent';
              return (
                <>
                  <div className={styles.leaveType}>{leave.leaveType}</div>
                  <div>{`${leave.employee?.firstName} ${leave.employee?.lastName}`}</div>
                  {subtext && <div className={styles.leaveTypeSubtext}>{subtext}</div>}
                </>
              );
            },
            renderCSV: (leave) => `${leave.employee?.firstName} ${leave.employee?.lastName}`,
          },
          { key: 'company__name', label: 'COMPANY', sort: true, render: (leave) => leave?.company.name },
          {
            key: 'created_at',
            label: 'Created',
            sort: true,
            render: (leave) => leave.createdAt && moment(leave.createdAt).format('MM/DD/YYYY'),
          },
          {
            key: 'stage_label',
            label: 'Status',
            sort: true,
            // eslint-disable-next-line react/display-name
            render: (leave) => <span className={styles.status}>{leaveStatusMap[leave.status]}</span>,
            renderCSV: (leave) => leaveStatusMap[leave.status],
          },
          {
            key: 'expected_leave_date',
            label: 'Leave Date',
            sort: true,
            // TODO convert this to local formatting
            render: (leave) => leave.expectedLeaveDate && moment(leave.expectedLeaveDate).format('MM/DD/YYYY'),
          },
          {
            key: 'assignee__first_name',
            label: 'Assigned',
            sort: true,
            // eslint-disable-next-line react/display-name
            render: (leave) => (
              <span className={styles.assigneeCell}>
                <LeaveAssigneeDropdown
                  selectedUserId={leave.assignee?.id}
                  users={superusers}
                  onChange={onLeaveAssigneeChange}
                  leaveId={leave.id}
                />
              </span>
            ),
            renderCSV: (leave) => getAssigneeForLeave(leave),
          },
        ]}
        onRowClick={(leave, e) => {
          if (e.metaKey || e.ctrlKey) {
            return window.open(`${window.location.href}leaves?leaveId=${leave.id}`, '_blank');
          }
          Router.push(`/leaves?leaveId=${leave.id}`);
        }}
        initialSort={leaveSortConfig}
        onServerSort={setLeaveSortConfig}
        serverPagination={{
          count: leaveCount,
          onPaginate: paginatePending,
          itemsPerPage: itemsPerPagePending,
          setItemsPerPage: setItemsPerPagePending,
        }}
      />
    </>
  );
};

export default shouldBeLoggedIn(HomePage);
